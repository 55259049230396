import styled from 'styled-components'

import * as breakpoint from '../../constants/breakpoints'
import * as color from '../../constants/colors'
import * as copy from '../copy'
import * as heading from '../heading'
import * as link from '../link'

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  transition: transform 0.3s ease-in-out;
`

export const Link = styled(link.Base)`
  display: block;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;

  // HACK: Safari messes up rendering of rasterised images (among other stuff) when scaled up
  // while having borders and hidden overflow. This forces size re-calculation, skipping the rendering glitch.
  -webkit-transform: translate3d(0, 0, 0);

  @media not screen and (${breakpoint.LAPTOP}) {
    min-width: 270px;
    min-height: 270px;
  }

  &:after {
    background: linear-gradient(
      180deg,
      rgba(19, 34, 42, 0) 0%,
      rgba(19, 34, 42, 0.1) 15%,
      rgba(19, 34, 42, 0.6) 30%,
      rgba(19, 34, 42, 0.7) 40%,
      rgba(19, 34, 42, 0.8) 100%
    );
    position: absolute;
    top: 0;
    content: '';
    height: 200%;
    width: 100%;
    transform: translateY(0%);
    transition: transform 0.3s ease-in-out;
  }

  @media screen and (${breakpoint.LAPTOP}) {
    &:hover {
      &:after {
        transform: translateY(-33%);
      }
      ${Image} {
        transform: scale(1.1);
      }
    }
  }
`

export const Data = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
`

export const Time = styled(copy.TextInfoSemiBold)`
  color: ${color.WHITE};
`

export const Title = styled(heading.TitleCardArticle)`
  color: ${color.WHITE};
  margin-top: 6px;
  margin-bottom: 4px;
`
