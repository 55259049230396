import * as next from 'next'

import * as cold from '@owl-nest/cold-data'
import * as core from '@bob/services'

import { type HomepageProps } from '@bob/homepage'
export { Homepage as default } from '@bob/homepage'

export async function getStaticProps({
  locale = cold.defaultLocale.code,
}: next.GetStaticPropsContext<{ locale: string }>): Promise<next.GetStaticPropsResult<HomepageProps>> {
  if (locale !== 'fr') {
    return {
      notFound: true,
    }
  }

  const homepage = await core.homepage.build.get({ locale })

  if (homepage === undefined) {
    return { notFound: true }
  }

  return {
    props: {
      homepage,
    },
    revalidate: 10,
  }
}
