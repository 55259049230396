import * as React from 'react'
import styled from 'styled-components'

import * as breakpoint from '../../constants/breakpoints'

import { BlobPicture } from '../BlobPicture'
import { Picture } from '../Picture'
import { Tag } from '../Tag'
import { CriterionList, Criteria } from './CriterionList'
import * as icons from '../../svg/icons'

import * as S from '../../styles'

export type BrandCardProps = {
  coverImage: string
  nativeLazyLoad?: boolean
  criteria: Criteria
  description: string | null
  logo: {
    alt: string | null
    height: number | null
    src: string
    width: number | null
  }
  name: string
  signatory: boolean
  tags: string[]
}

function BrandCardComponent({
  coverImage,
  nativeLazyLoad,
  criteria,
  description,
  logo,
  name,
  signatory,
  tags,
  ...rest
}: BrandCardProps): React.ReactElement {
  return (
    <S.card.brand.Card {...rest}>
      <S.card.brand.Cover>
        <Picture nativeLazyLoad={nativeLazyLoad} src={coverImage} />
      </S.card.brand.Cover>
      <S.card.brand.Body>
        <S.card.brand.Header>
          <BlobPicture color="light-green" size={64} sizing="contain" src={logo.src} variant={3} />
          <S.card.brand.Name>{name}</S.card.brand.Name>
        </S.card.brand.Header>
        <CriterionList maxToShow={6} criteria={criteria} />
        <S.card.brand.Description>
          <p>{description}</p>
        </S.card.brand.Description>
        <S.card.brand.Footer>
          {signatory && (
            <Tag variant="yellow">
              <icons.Hands />
              Signataire
            </Tag>
          )}
          {tags.map((tag) => (
            <Tag key={tag} variant="light-green">
              {tag}
            </Tag>
          ))}
        </S.card.brand.Footer>
      </S.card.brand.Body>
    </S.card.brand.Card>
  )
}

export const BrandCard = styled(BrandCardComponent)`
  ${S.card.brand.Cover} {
    ${S.picture.Image} {
      transition: transform 0.3s ease-in-out;
    }
  }

  @media screen and (${breakpoint.LAPTOP}) {
    &:hover {
      ${S.card.brand.Cover} {
        ${S.picture.Image} {
          transform: scale(1.1);
        }
      }
    }
  }
`

type BrandCardSkeletonProps = {
  className?: string
}

function BrandCardSkeletonComponent({ className }: BrandCardSkeletonProps) {
  return (
    <S.card.brand.Card className={className}>
      <S.card.brand.Cover>
        <S.skeleton.Rectangle height={180} />
      </S.card.brand.Cover>
      <S.card.brand.Body>
        <S.card.brand.Header>
          <S.skeleton.Circle radius={64} />
          <div style={{ marginLeft: '12px', width: '72%' }}>
            <S.skeleton.Text />
            <S.skeleton.Text width={80} />
          </div>
        </S.card.brand.Header>
        <S.card.brand.Criteria />
        <S.card.brand.Description>
          <S.skeleton.Text size="small" />
          <S.skeleton.Text size="small" />
          <S.skeleton.Text size="small" />
        </S.card.brand.Description>
        <S.card.brand.Footer>
          <S.skeleton.Tag />
        </S.card.brand.Footer>
      </S.card.brand.Body>
    </S.card.brand.Card>
  )
}

export const BrandCardSkeleton = styled(BrandCardSkeletonComponent)`
  ${S.card.brand.Cover} {
    ${S.skeleton.Rectangle} {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      overflow: hidden;

      @media screen and (${breakpoint.TABLET}) {
        height: 150px !important;
      }
    }
  }
`
