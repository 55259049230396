import styled from 'styled-components'

import * as ds from '@bob/design-system'

import * as svg from './svg'

export function Partnership(): React.ReactElement {
  return (
    <Section>
      <ds.styles.copy.Text5 as="p">
        Un collectif <br />
        fièrement soutenu par
      </ds.styles.copy.Text5>
      <LogoWrapper>
        <svg.Arrow />
        <svg.Maif />
        <svg.Ulule />
      </LogoWrapper>
    </Section>
  )
}

const Section = styled.section`
  @media not screen and (${ds.breakpoint.TABLET}) {
    position: relative;
    padding-bottom: 60px;
    margin-top: 50px;
  }

  @media screen and (${ds.breakpoint.TABLET}) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 81px 0 49px;
  }

  ${ds.styles.copy.Text5} {
    @media not screen and (${ds.breakpoint.TABLET}) {
      margin-bottom: 10px;
      text-align: center;
    }

    @media screen and (${ds.breakpoint.TABLET}) {
      max-width: 200px;
      flex-grow: 1;
    }
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (${ds.breakpoint.TABLET}) {
    margin-left: 50px;
  }
`
