import styled from 'styled-components'
import * as breakpoints from '../../constants/breakpoints'

export const Criterion = styled.li`
  margin-right: 10px;
`

export const Criteria = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0px;
  padding: 0px;

  @media screen and (${breakpoints.TABLET}) {
    margin-bottom: 4px;
    margin-top: 8px;
  }
`

export const CriteriaEllipsis = styled.li`
  align-self: center;
`
