import * as React from 'react'
import Link from 'next/link'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'

import * as ds from '@bob/design-system'
import * as url from '@bob/url'

import { BrushTitleAnimated } from '@bob/components'

export function Manifesto(): React.ReactElement {
  const inViewOptions = {
    threshold: 0.0,
    initialInView: false,
    triggerOnce: true,
  }
  const [$manifesto, inView] = useInView(inViewOptions)

  return (
    <Wrapper ref={$manifesto}>
      <ds.styles.heading.Title3>
        Comme vous, on a envie que le monde de demain soit vraiment{' '}
        <Brush brushId="pink" inView={inView} delay={0.9}>
          bien
        </Brush>
        . Les marques signataires de la charte Bien ou Bien s&apos;engagent pour une mission commune : produire{' '}
        <Brush brushId="green" inView={inView} delay={1.1}>
          mieux
        </Brush>
        , dans le respect des humains et de l&apos;environnement. Ici, on met en lumière ces créatrices et créateurs,
        pour vous aider à faire les bons choix dans une démarche de consommation{' '}
        <Brush brushId="yellow" inView={inView} delay={1.3}>
          engagée
        </Brush>
        , plus durable.
      </ds.styles.heading.Title3>

      <Link prefetch={false} href={`${url.manifesto().path}`} passHref legacyBehavior>
        <ds.ButtonAsLink autoSized>Consulter la charte</ds.ButtonAsLink>
      </Link>
    </Wrapper>
  )
}

export const BrushPinkBackground = ds.utils.svg.toDataUrl(ds.brush.full.FullBrush5, {
  width: 482,
  height: 91,
  fill: ds.color.PINK,
})

export const BrushGreenBackground = ds.utils.svg.toDataUrl(ds.brush.full.FullBrush2, {
  width: 482,
  height: 91,
  fill: ds.color.LIGHT_GREEN,
})

export const BrushYellowBackground = ds.utils.svg.toDataUrl(ds.brush.full.FullBrush4, {
  width: 482,
  height: 91,
  fill: ds.color.YELLOW,
})

export const Brush = styled.span<{ brushId?: string; inView?: boolean; delay?: number }>`
  display: inline-block;
  position: relative;
  z-index: -3;

  @keyframes brush-slide {
    from {
      clip-path: inset(0 100% 0 0);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    top: -0.5em;
    left: -1em;
    bottom: -0.5em;
    right: -0.75em;
  }

  &::before {
    z-index: -3;
    background-image: ${({ brushId }) =>
      brushId === 'pink'
        ? `url('${BrushPinkBackground}')`
        : brushId === 'green'
        ? `url('${BrushGreenBackground}')`
        : `url('${BrushYellowBackground}')`};
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: center;

    animation: brush-slide 0.4s ease-in-out both;
    animation-play-state: paused;
    animation-delay: ${({ delay = 0 }) => `calc(0.1s * var(--word-index, 0) + 0.2s + ${delay}s)`};

    ${({ inView }) =>
      inView &&
      css`
        animation-play-state: running;
      `}
  }
`

const Wrapper = styled.div`
  text-align: center;
  margin: 0px auto 80px;
  max-width: 1184px;

  ${ds.ButtonAsLink} {
    margin-top: 50px;
  }
`
