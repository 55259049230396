import * as React from 'react'
import styled from 'styled-components'

import * as S from '../../styles'

import { useLinkWrapper } from '../../hooks/useLinkWrapper'
import { Tag } from '../Tag'
import { LazyImage } from '../LazyImage'

export type ArticleCardProps = {
  href: string
  date?: string
  image: {
    src: string
    alt?: string | null
    width?: number | null
    height?: number | null
    nativeLazyLoad?: boolean
  }
  tag?: string
  title: string
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

function ArticleCardComponent({ href, date, image, tag, title, ...rest }: ArticleCardProps): React.ReactElement {
  const LinkWrapper = useLinkWrapper()
  return (
    <LinkWrapper href={href} passHref legacyBehavior>
      <S.card.article.Link {...rest}>
        <LazyImage nativeLazyLoad={image.nativeLazyLoad}>
          <S.card.article.Image
            src={image.src}
            alt={image.alt || title}
            width={image.width ?? undefined}
            height={image.height ?? undefined}
          />
        </LazyImage>
        <S.card.article.Data>
          {tag && <Tag variant="white">{tag}</Tag>}
          <S.card.article.Title>{title}</S.card.article.Title>
          {date && <S.card.article.Time as="time">{date}</S.card.article.Time>}
        </S.card.article.Data>
      </S.card.article.Link>
    </LinkWrapper>
  )
}

export const ArticleCard = styled(ArticleCardComponent)``

function ArticleCardSkeletonComponent(): React.ReactElement {
  return (
    <S.skeleton.SquareCard>
      <S.card.article.Data>
        <S.skeleton.Tag tint="light" />
        <S.skeleton.Text tint="light" />
        <S.skeleton.Text tint="light" width={80} />
        <S.skeleton.Text size="small" tint="light" width={24} />
      </S.card.article.Data>
    </S.skeleton.SquareCard>
  )
}

export const ArticleCardSkeleton = styled(ArticleCardSkeletonComponent)``
