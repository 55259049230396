import * as React from 'react'
import styled from 'styled-components'

import * as S from '../../styles'
import * as illustration from '../../svg/illustrations'
import { Tooltip } from '../Tooltip'

export type Criteria = {
  icon: string
  name: string
}[]

type CriterionListProps = {
  maxToShow: number
  criteria: Criteria
}

function CriterionListComponent({ maxToShow, criteria }: CriterionListProps) {
  return (
    <S.card.criterion.Criteria>
      {criteria.reduce<JSX.Element[]>((criteriaAccumulator, criterion, index) => {
        if (index < maxToShow) {
          const Illustration = (illustration.criteria as any)[criterion.icon]
          criteriaAccumulator.push(
            <S.card.criterion.Criterion key={criterion.icon}>
              <Tooltip title={criterion.name} placement="top">
                <Illustration size={24} />
              </Tooltip>
            </S.card.criterion.Criterion>,
          )
        }
        if (index === maxToShow) {
          criteriaAccumulator.push(
            <S.card.criterion.CriteriaEllipsis key="rest">
              <S.copy.Text3>{`+${criteria.length - maxToShow}`}</S.copy.Text3>
            </S.card.criterion.CriteriaEllipsis>,
          )
        }

        return criteriaAccumulator
      }, [])}
    </S.card.criterion.Criteria>
  )
}
export const CriterionList = styled(CriterionListComponent)``
