import * as React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import * as ds from '@bob/design-system'
import { styles as S } from '@bob/design-system'

export type TestimonialProps = {
  name: string
  society: string
  text: string
  imgUrl: string
  href: string
  nativeLazyLoad?: boolean
  blob: {
    variant: keyof typeof ds.BLOB_VARIANTS
    color: keyof typeof ds.BLOB_COLORS
  }
}

function TestimonialComponent({
  name,
  society,
  text,
  imgUrl,
  blob,
  href,
  nativeLazyLoad = false,
  ...rest
}: TestimonialProps): React.ReactElement {
  return (
    <Link prefetch={false} href={href} passHref legacyBehavior>
      <Wrapper {...rest}>
        <ds.BlobPicture
          nativeLazyLoad={nativeLazyLoad}
          src={imgUrl}
          variant={blob.variant}
          color={blob.color}
          size={300}
        />
        <Body>
          <S.heading.Title4>{name}</S.heading.Title4>
          <SubTitle>{society}</SubTitle>
          <Text>{text}</Text>
          <ds.LinkButtonAsFake>Découvrir</ds.LinkButtonAsFake>
        </Body>
      </Wrapper>
    </Link>
  )
}

export const Wrapper = styled.a`
  text-align: center;

  @media screen and (${ds.breakpoint.MOBILE_L}) {
    max-width: 370px;
  }
`

export const Body = styled.div`
  padding: 20px 20px 0;
  text-align: left;
`

export const SubTitle = styled.div`
  font-size: 30px;
  font-family: ${ds.font.PRIMARY};
  line-height: 1;

  @media screen and (${ds.breakpoint.MOBILE_L}) {
    font-size: 35px;
  }
`

export const Text = styled.p`
  margin: 20px 0;
`

export const Testimonial = styled(TestimonialComponent)`
  ${S.heading.Title4}, ${SubTitle} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${S.picture.Image} {
    transition: transform 0.3s ease-in-out;
  }

  ${ds.Picture} {
    @media not screen and (${ds.breakpoint.MOBILE_L}) {
      width: 270px;
      height: 270px;
    }
  }

  @media screen and (${ds.breakpoint.LAPTOP}) {
    &:hover {
      ${S.linkButton.FakeLinkButton} {
        &::before {
          transform: translateX(0);
        }
        svg {
          transform: translateX(5px);
        }
      }

      ${S.picture.Image} {
        transform: scale(1.1);
      }
    }
  }
`
