import styled, { css } from 'styled-components'

const layout = css`
  display: flex;
  max-width: 900px;
  margin: 0 auto;
`

export const Slide_spaced = styled.div`
  ${layout};
  justify-content: space-between;
`

export const Slide = styled.div`
  ${layout};
  justify-content: center;
`
