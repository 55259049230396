import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

import { useLinkWrapper } from '../hooks/useLinkWrapper'
import { LinkButtonAsFake } from './LinkButtonAsFake'

export type GuideProps = {
  title: React.ReactNode
  text: string
  illustration: React.ReactNode
  linkUrl: string
} & React.HTMLAttributes<HTMLElement>

function GuideComponent({ title, text, illustration, linkUrl, ...rest }: GuideProps): React.ReactElement {
  const LinkWrapper = useLinkWrapper()
  return (
    <LinkWrapper href={linkUrl} passHref legacyBehavior>
      <S.guide.Wrapper {...rest}>
        <S.guide.Title>
          <S.guide.TitleContent>{title}</S.guide.TitleContent>
        </S.guide.Title>
        <S.guide.Grid>
          <S.guide.Illustration>{illustration}</S.guide.Illustration>
          <div>
            <S.guide.Text>{text}</S.guide.Text>
            <LinkButtonAsFake>Découvrir</LinkButtonAsFake>
          </div>
        </S.guide.Grid>
      </S.guide.Wrapper>
    </LinkWrapper>
  )
}

export const Guide = styled(GuideComponent)`
  &:hover {
    ${S.linkButton.FakeLinkButton} {
      &::before {
        transform: translateX(0);
      }
      svg {
        transform: translateX(5px);
      }
    }
  }
`
