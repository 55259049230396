import * as React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import * as ds from '@bob/design-system'
import { styles as S } from '@bob/design-system'
import * as url from '@bob/url'
import * as utils from '@bob/utils'

type CategoriesProps = {
  categoryList: {
    icon: string
    name: string
    slug: string
  }[]
}

export function Categories({ categoryList }: CategoriesProps): React.ReactElement {
  return (
    <>
      <CenteredTitle>Les marques engagées par catégories</CenteredTitle>
      <CategoryList>
        {categoryList.map((category, i) => {
          const Illustration = (ds.illustrations.categories as any)[category.icon]

          const blob = utils.blob.useBlobData()

          return (
            <Link
              key={category.name}
              href={`${url.brands().path}?categories=${category.slug}`}
              prefetch={false}
              passHref
              legacyBehavior
            >
              <Item order={i + 1}>
                <ds.CategoryLink {...category} blob={blob} illustration={<Illustration />} vertical={false} />
              </Item>
            </Link>
          )
        })}
      </CategoryList>
    </>
  )
}

export const CenteredTitle = styled(S.heading.Title2)`
  text-align: center;
  margin: 0 auto;
  max-width: 333px;

  @media screen and (${ds.breakpoint.TABLET}) {
    max-width: 1135px;
  }
`

export const CategoryList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 75px;
  margin-top: 28px;

  @media screen and (${ds.breakpoint.TABLET}) {
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 80px;
    margin-top: 30px;
    flex-direction: row;
  }
`

export const Item = styled.a<{ order: number }>`
  padding: 10px;
  width: 120px;

  ${ds.CategoryLink} {
    display: flex;
    flex-direction: column;

    ${S.categoryLink.Title} {
      margin-left: 0;
    }
  }
`
