import styled, { css } from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as color from '../constants/colors'

export type props = {
  vertical: boolean
}

/* Considering to leave it as div since there's a couple of usage without navigation */
const LinkBase = styled.div`
  position: relative;
  display: flex;
  text-decoration: none;
`

export const Link = styled(LinkBase)<props>`
  ${({ vertical }) =>
    vertical
      ? css`
          align-items: center;
        `
      : css`
          flex-direction: column;
          align-items: center;
          text-align: center;
          height: 100%;
        `}
`

export const Title = styled.span`
  align-items: center;
  color: ${color.GRAY_3};
  display: flex;
  flex-grow: 1;
  letter-spacing: -1px;
  margin-left: 10px;
  position: relative;
  z-index: 1;

  @media screen and (${breakpoint.TABLET}) {
    justify-content: center;
    margin-left: 0;
  }
`

const IllustrationBase = styled.div`
  position: relative;
  z-index: 1;
  flex-shrink: 0;
`

export const Illustration = styled(IllustrationBase)<props>`
  ${({ vertical }) =>
    vertical
      ? css`
          width: 65px;
          height: 65px;
          margin-right: 30px;
        `
      : css`
          width: 75px;
          height: 75px;
          margin-bottom: 2px;
        `}
`
