import * as entity from '@boutique/entities'

import { Section } from './Section'
import { Testimonials } from '@bob/components'

export type HomeTestimonialsProps = React.HTMLAttributes<HTMLDivElement> & {
  homepage: entity.homepage.Homepage
}

export function HomeTestimonials({ homepage, ...rest }: HomeTestimonialsProps): React.ReactElement {
  return (
    <Section {...rest} title="Histoires de créateurs &&nbsp;créatrices">
      <Testimonials animated={false} nativeLazyLoad items={homepage.highlights} />
    </Section>
  )
}
