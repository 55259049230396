import styled from 'styled-components'

import * as breakpoint from '../../constants/breakpoints'
import * as color from '../../constants/colors'
import * as copy from '../copy'
import * as fonts from '../../constants/fonts'
import * as heading from '../heading'
import * as picture from '../picture'
import * as tag from '../tag'

export const Body = styled.section`
  background-color: ${color.SAND_2};
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  height: 100%;
  padding: 15px;
  position: relative;
`

export const Card = styled.section`
  display: flex;
  flex-direction: column;
  height: 430px;
  min-width: 293px;

  @media screen and (${breakpoint.TABLET}) {
    height: 400px;
  }

  @media screen and (${breakpoint.LAPTOP}) {
    min-width: 213px;
  }
`

export const Cover = styled.header`
  ${picture.Picture} {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    overflow: hidden;
  }
  ${picture.Image} {
    height: 180px;
  }

  @media screen and (${breakpoint.TABLET}) {
    ${picture.Image} {
      height: 150px;
    }
  }
`

export const Criteria = styled.ul`
  display: flex;
  margin: 15px 0;
`

export const CriteriaEllipsis = styled.li`
  align-self: center;
`

export const Criterion = styled.li`
  margin-right: 10px;
`

const DESCRIPTION_LINE_HEIGHT = '18px'
const MAX_DESCRIPTION_LINES = 3

export const Description = styled(copy.Text3)`
  /* FIXME: We shouldn't have to override such values */
  font-weight: ${fonts.WEIGHT.BASE};
  line-height: ${DESCRIPTION_LINE_HEIGHT};

  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${MAX_DESCRIPTION_LINES};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;

  // Line clamping support for older Firefox versions and other non-Webkit antiques
  @supports not (-webkit-line-clamp: ${MAX_DESCRIPTION_LINES}) {
    max-height: calc(${DESCRIPTION_LINE_HEIGHT} * ${MAX_DESCRIPTION_LINES});
    overflow: hidden;
    padding-right: 16px;
    position: relative;

    &::after {
      background: transparent;
      content: '';
      inset-inline-end: 0;
      position: absolute;
    }

    &::before {
      content: '…';
      inset-block-end: 0;
      inset-inline-end: 5px;
      position: absolute;
    }
  }
`

export const Footer = styled.footer`
  bottom: 15px;
  display: flex;
  padding: 15px 0 0;
  position: absolute;
  left: 15px;
  right: 15px;

  ${tag.Tag} {
    text-align: center;
    svg {
      height: 12px;
      width: 20px;
    }

    & + ${tag.Tag} {
      margin-left: 8px;
    }
  }
`

export const Header = styled.header`
  align-items: center;
  display: flex;
`

export const Name = styled(heading.TitleCard)`
  margin-left: 24px;
`
