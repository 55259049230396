import * as React from 'react'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { styles as S } from '@bob/design-system'

import { blob } from '@bob/utils'

import { TestimonialsBig } from './TestimonialsBig'
import { TestimonialsSmall } from './TestimonialsSmall'
import { Testimonial } from './Testimonial'

export type TestimonialsProps = React.HTMLAttributes<HTMLDivElement> & {
  items: {
    description: string
    image: {
      alt: string | null
      credit?: string | null
      height: number | null
      origin: string
      src: string
      width: number | null
    }
    link: string
    title: string
    subtitle: string
  }[]
  nativeLazyLoad?: boolean
  animated?: boolean
}

function TestimonialsComponent({
  items,
  nativeLazyLoad = false,
  animated = true,
  ...rest
}: TestimonialsProps): React.ReactElement {
  blob.resetBlob()

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.35,
    skip: !animated,
  })

  return (
    <TestimonialsSection {...rest} ref={ref} animated={animated} inView={inView}>
      <TestimonialsSmall>
        {items.map((item) => {
          return (
            <Testimonial
              nativeLazyLoad={nativeLazyLoad}
              key={item.title + item.subtitle + item.description}
              name={item.title}
              society={item.subtitle}
              href={item.link}
              imgUrl={item.image.src}
              text={item.description}
              blob={blob.blob()}
            />
          )
        })}
      </TestimonialsSmall>
      <TestimonialsBig>
        {group(items, 2).map((page) => {
          return page.map((item) => {
            return (
              <Testimonial
                nativeLazyLoad={nativeLazyLoad}
                key={item.title + item.subtitle + item.description}
                name={item.title}
                society={item.subtitle}
                href={item.link}
                imgUrl={item.image.src}
                text={item.description}
                blob={blob.blob()}
              />
            )
          })
        })}
      </TestimonialsBig>
    </TestimonialsSection>
  )
}

const TestimonialsSection = styled.section<{ animated: boolean; inView: boolean }>`
  ${S.carousel.ControlPrev}, ${S.carousel.ControlNext}, ${Testimonial}:nth-child(1), ${Testimonial}:nth-child(2) {
    transition-property: opacity;
    transition-timing-function: ease-in-out;

    ${({ animated = true, inView }) => {
      if (!animated) {
        return css`
          opacity: 1;
        `
      }
      if (inView) {
        return css`
          opacity: 1;
        `
      } else {
        return css`
          opacity: 0;
        `
      }
    }}
  }

  ${S.carousel.ControlPrev},${S.carousel.ControlNext} {
    transition-duration: 400ms;
  }
  ${Testimonial}:nth-child(1),${Testimonial}:nth-child(2) {
    transition-duration: 500ms;
  }

  ${S.carousel.ControlPrev} {
    transition-delay: 1000ms;
  }
  ${S.carousel.ControlNext} {
    transition-delay: 1200ms;
  }

  ${Testimonial}:nth-child(1) {
    transition-delay: 500ms;
  }
  ${Testimonial}:nth-child(2) {
    transition-delay: 800ms;
  }
`

export const Testimonials = styled(TestimonialsComponent)`
  ${S.carousel.Wrapper} {
    align-items: normal;
  }

  ${S.carousel.ControlPrev}, ${S.carousel.ControlNext} {
    margin-top: 247px;
    top: 0;
    transform: none;
  }
`

function group<T>(array: T[], by: number): T[][] {
  const grouped: T[][] = []
  let current: T[] = []
  for (const item of array) {
    if (current.length === by) {
      grouped.push(current)
      current = []
    }
    current.push(item)
  }
  if (current.length !== 0) {
    grouped.push(current)
  }

  return grouped
}
