import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

export type TooltipProps = React.ButtonHTMLAttributes<HTMLElement> & {
  title: string
} & S.tooltip.WrapperProps

function TooltipComponent({ title, placement, children, show, ...rest }: TooltipProps): React.ReactElement {
  return (
    <S.tooltip.Wrapper {...rest} show={show} placement={placement} data-tooltip={title}>
      {children}
    </S.tooltip.Wrapper>
  )
}

export const Tooltip = styled(TooltipComponent)``
