import * as React from 'react'
import styled from 'styled-components'

import * as ds from '@bob/design-system'

import * as S from './style'

export type TestimonialsBigProps = Omit<React.ButtonHTMLAttributes<HTMLElement>, 'children'> & {
  children: React.ReactNode[]
}

function TestimonialsBigComponent({ children, ...rest }: TestimonialsBigProps): React.ReactElement {
  return (
    <ds.Carousel {...rest}>
      {children.map((children, i) => {
        return <S.Slide_spaced key={i}>{children}</S.Slide_spaced>
      })}
    </ds.Carousel>
  )
}

export const TestimonialsBig = styled(TestimonialsBigComponent)`
  @media not screen and (${ds.breakpoint.LAPTOP}) {
    display: none;
  }
`
