interface Hash {
  update(str: string): Hash
  digest(type: 'hex'): string
  digest(type: 'number'): number
  digest(type: 'number' | 'hex'): number | string
}

export function createHash(): Hash {
  let state = 0

  const hash = {
    update,
    digest,
  }

  return hash

  function update(str: string) {
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      state = (state << 5) - state + char
      state = state & state // Convert to 32bit integer
    }
    return hash
  }

  function digest(type: 'hex'): string
  function digest(type: 'number'): number
  function digest(type: 'hex' | 'number'): string | number {
    if (type === 'hex') {
      return Math.abs(state).toString(16)
    } else {
      return Math.abs(state)
    }
  }
}
