import styled from 'styled-components'

import * as ds from '@bob/design-system'
import * as newsletter from '@bob/newsletter-form'

export function Newsletter() {
  return (
    <Wrapper>
      <ds.stickers.others.Newsletter size={100} />
      <NewsletterBody>
        <ds.styles.heading.Title3>
          Inscrivez-vous à la newsletter qui vous aide à mieux consommer
        </ds.styles.heading.Title3>
        <newsletter.NewsletterForm />
        <ds.styles.copy.Text1>
          Bien ou Bien ne vendra pas votre adresse, et vous pourrez arrêter votre abonnement quand vous voulez.{' '}
          <strong>Aussi, vous recevrez un petit cadeau à l&apos;inscription 🙂</strong>
        </ds.styles.copy.Text1>
      </NewsletterBody>
    </Wrapper>
  )
}

const NewsletterBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${ds.color.SAND_2};
  border-radius: 12px;

  margin: 25px 0 50px;
  position: relative;

  ${ds.styles.heading.Title3} {
    margin-bottom: 20px;
    text-align: center;
    max-width: 600px;
  }

  ${ds.styles.copy.Text1} {
    margin-top: 20px;
    opacity: 0.75;
  }

  ${newsletter.NewsletterForm} {
    display: flex;
    flex-direction: column;
    width: 100%;

    ${ds.Input} {
      flex: 2;
      margin-bottom: 10px;
    }

    ${ds.Button} {
      flex: 1;
    }
  }

  @media screen and (${ds.breakpoint.MOBILE_S}) {
    padding: 20px 50px;
  }

  @media screen and (${ds.breakpoint.MOBILE_M}) {
    padding: 35px 50px;
  }

  @media screen and (${ds.breakpoint.TABLET}) {
    padding: 40px 50px;

    ${newsletter.NewsletterForm} {
      flex-direction: row;

      ${ds.Input} {
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
  }

  ${ds.stickers.others.Newsletter} {
    position: absolute;
    z-index: 2;
    right: 0px;
    bottom: -11%;
    display: none;

    @media screen and (${ds.breakpoint.MOBILE_M}) {
      display: block;
    }

    @media screen and (${ds.breakpoint.LAPTOP}) {
      height: 125px;
      transform: none;
      left: -15px;
      top: -15px;
      width: 125px;
    }

    @media screen and (${ds.breakpoint.LAPTOP_L}) {
      transform: none;
      left: 60px;
      top: 25%;
    }
  }
`
