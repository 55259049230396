import * as React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import * as entity from '@boutique/entities'
import * as utils from '@boutique/utils'
import * as url from '@bob/url'
import * as ds from '@bob/design-system'

export type BrandsProps = {
  homepage: entity.homepage.Homepage
}

export function Brands({ homepage }: BrandsProps): React.ReactElement {
  return (
    <>
      <ds.LineGridLayout>
        {homepage.brands.map((brand) => {
          return (
            <Link
              key={brand.slug}
              prefetch={false}
              href={
                url.brand({
                  slug: brand.slug,
                  name: brand.name,
                }).path
              }
            >
              <ds.BrandCard
                name={brand.name}
                coverImage={brand.coverImage?.src ?? ''}
                criteria={[...brand.pillars.environmental, ...brand.pillars.social].map((criterion) => ({
                  name: criterion.name,
                  icon: utils.symbols.getCriterionSymbolName(criterion.pillar.icon),
                }))}
                signatory={brand.signatory}
                tags={Array.from(new Set(brand.brandCategories.map((category) => category.name)))}
                description={brand.descriptionShort}
                logo={brand.logo}
              />
            </Link>
          )
        })}
      </ds.LineGridLayout>
      <ButtonContainer>
        <ds.ButtonAsLink autoSized href={url.brands().path}>
          Voir toutes les marques
        </ds.ButtonAsLink>
      </ButtonContainer>
    </>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 42px;
`
