import * as React from 'react'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'

import * as ds from '@bob/design-system'
import { styles as S } from '@bob/design-system'
import * as url from '@bob/url'
import * as entity from '@boutique/entities'

export type GuideProps = React.ButtonHTMLAttributes<HTMLElement> & {
  homepage: entity.homepage.Homepage
}

function GuideComponent({ homepage, ...rest }: GuideProps): React.ReactElement {
  const guideUrl = url.articles()

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.25,
  })

  return (
    <Section {...rest} ref={ref} inView={inView}>
      <ds.Container>
        <S.heading.Title1>Les guides pratiques</S.heading.Title1>
      </ds.Container>
      <Body>
        <ds.Container>
          <Grid>
            <ds.Guide
              title={homepage.guides.fashion.name ?? guideList.fashion.title}
              text={homepage.guides.fashion.description ?? guideList.fashion.text}
              illustration={guideList.fashion.illustration}
              linkUrl={homepage.guides.fashion.url ?? guideUrl.path + guideList.fashion.searchParams}
            />
            <ds.Guide
              title={homepage.guides.alimentation.name ?? guideList.alimentation.title}
              text={homepage.guides.alimentation.description ?? guideList.alimentation.text}
              illustration={guideList.alimentation.illustration}
              linkUrl={homepage.guides.alimentation.url ?? guideUrl.path + guideList.alimentation.searchParams}
            />
            <ds.Guide
              title={homepage.guides.cosmetic.name ?? guideList.cosmetic.title}
              text={homepage.guides.cosmetic.description ?? guideList.cosmetic.text}
              illustration={guideList.cosmetic.illustration}
              linkUrl={homepage.guides.cosmetic.url ?? guideUrl.path + guideList.cosmetic.searchParams}
            />
            <ds.Guide
              title={homepage.guides.house.name ?? guideList.house.title}
              text={homepage.guides.house.description ?? guideList.house.text}
              illustration={guideList.house.illustration}
              linkUrl={homepage.guides.house.url ?? guideUrl.path + guideList.house.searchParams}
            />
          </Grid>
        </ds.Container>
      </Body>
    </Section>
  )
}

const guideList = {
  fashion: {
    title: 'Guide de la mode',
    text: 'Parce qu’on veut vivre dans des vêtements confortables et jolis… Tout en cherchant des tissus et des fabrications éthiques!',
    illustration: <ds.illustrations.categories.Fashion />,
    searchParams: '?categories=mode-et-accessoires',
  },
  alimentation: {
    title: 'Guide de l’alimentation',
    text: 'Parce qu’on aspire à manger des choses délicieuses… Aussi bonnes pour les producteurs et la planète que pour nous. ',
    illustration: <ds.illustrations.categories.Food />,
    searchParams: `?categories=alimentation`,
  },
  cosmetic: {
    title: 'Guide de la cosmétique',
    text: 'Parce que même si la beauté est intérieure, on aime bien utiliser deux ou trois flacons… Autant les choisir respectueux de l’environnement et de nos corps !',
    illustration: <ds.illustrations.categories.Hygiene />,
    searchParams: `?categories=hygiene-beaute-et-bien-etre`,
  },
  house: {
    title: 'Guide de la maison',
    text: 'Parce qu’on rêve d’une maison où l’on se sent bien… Sans contribuer à la déforestation de l’Amazonie ni à la pollution de notre home sweet home.',
    illustration: <ds.illustrations.categories.House />,
    searchParams: `?categories=maison-et-jardin`,
  },
}

export const Guide = styled(GuideComponent)``

const Body = styled.section`
  background: ${ds.color.PINK};
  margin-top: 50px;
  padding: 80px 0;
`

const Grid = styled.div`
  display: grid;
  gap: 50px;

  @media screen and (${ds.breakpoint.TABLET}) {
    gap: 80px;
    grid-template-columns: repeat(2, 1fr);
  }
`

const Section = styled.section<{ inView: boolean }>`
  padding-top: 50px;

  ${Body} {
    opacity: 0;
    transform: translateX(-100%);
    transition-delay: 400ms;
    transition-duration: 500ms;
    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;

    ${({ inView }) =>
      inView &&
      css`
        opacity: 1;
        transform: translateX(0%);
      `}
  }

  ${ds.Guide} {
    opacity: 0;
    transform: translateX(15px);
    transition-duration: 350ms;
    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;
  }

  ${ds.Guide}:nth-child(1) {
    transition-delay: 1000ms;

    ${({ inView }) =>
      inView &&
      css`
        opacity: 1;
        transform: translateY(0px);
      `}
  }

  ${ds.Guide}:nth-child(2) {
    transition-delay: 1300ms;

    ${({ inView }) =>
      inView &&
      css`
        opacity: 1;
        transform: translateY(0px);
      `}
  }

  ${ds.Guide}:nth-child(3) {
    transition-delay: 1600ms;

    ${({ inView }) =>
      inView &&
      css`
        opacity: 1;
        transform: translateY(0px);
      `}
  }

  ${ds.Guide}:nth-child(4) {
    transition-delay: 1900ms;

    ${({ inView }) =>
      inView &&
      css`
        opacity: 1;
        transform: translateY(0px);
      `}
  }
`
