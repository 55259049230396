import * as React from 'react'
import styled from 'styled-components'

import { styles as S } from '@bob/design-system'
import * as ds from '@bob/design-system'

export type SectionProps = {
    title: React.ReactNode
    text?: React.ReactNode
    footer?: React.ReactNode
} & Omit<React.ButtonHTMLAttributes<HTMLElement>, 'title'>

function SectionArticlesComponent(
    { title, text, children, footer, ...rest }: SectionProps,
    ref: React.Ref<HTMLElement>
): React.ReactElement {
    return (
        <SectionWrapper {...rest} ref={ref}>
            <S.heading.Title1>{title}</S.heading.Title1>
            {text && <Text>{text}</Text>}
            {children && <Body>{children}</Body>}
            {footer && <Footer>{footer}</Footer>}
        </SectionWrapper>
    )
}

export const Section = styled(React.forwardRef(SectionArticlesComponent))``

function SectionBorderlessComponent(
    { title, text, children, footer, ...rest }: SectionProps,
    ref: React.Ref<HTMLElement>
): React.ReactElement {
    return (
        <SectionWrapper {...rest} ref={ref}>
            <ds.Container>
                <S.heading.Title1>{title}</S.heading.Title1>
                {text && <Text>{text}</Text>}
            </ds.Container>
            {children && <Body>{children}</Body>}
            {footer && <Footer>{footer}</Footer>}
        </SectionWrapper>
    )
}

export const SectionBorderless = styled(React.forwardRef(SectionBorderlessComponent))`
    ${S.button.ButtonAsLink} {
        @media not screen and (${ds.breakpoint.TABLET}) {
            margin: 0 30px;
            width: calc(100% - 60px);
        }
    }
`

export const SectionWrapper = styled.section`
    padding: 50px 0;
`

export const Text = styled.p`
    max-width: 540px;
    margin-top: 10px;

    @media screen and (${ds.breakpoint.LAPTOP}) {
        margin-top: 20px;
    }
`

export const Body = styled.div`
    margin-top: 30px;

    @media screen and (${ds.breakpoint.LAPTOP}) {
        margin-top: 50px;
    }
`
export const Footer = styled.div`
    text-align: center;
    margin-top: 30px;

    @media screen and (${ds.breakpoint.LAPTOP}) {
        margin-top: 50px;
    }
`
