import styled from 'styled-components'

import * as entity from '@boutique/entities'
import * as url from '@bob/url'
import * as ds from '@bob/design-system'

import { Section, Text } from './Section'

export type ActusProps = {
  homepage: entity.homepage.Homepage
}

export function Actus({ homepage }: ActusProps): React.ReactElement {
  return (
    <ActusSection
      title="Les articles"
      text="On vous partage nos sélections, coups de cœur, actus et guides pratiques."
      footer={
        <ds.ButtonAsLink autoSized href={url.articles().path}>
          Voir tous les articles
        </ds.ButtonAsLink>
      }
    >
      <Sticker>
        <ds.stickers.others.WhatsUp size={125} />
      </Sticker>
      <ds.LineGridLayout>
        {homepage.articles.map((article) => {
          return (
            <ds.ArticleCard
              key={article.slug}
              href={
                url.article({
                  slug: article.slug,
                  title: article.title,
                }).path
              }
              title={article.title}
              date={new Date(
                article.forceUpdatedAt ? article.forceUpdatedAt : article.firstPublishedAt ?? '',
              ).toLocaleDateString('fr-FR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })}
              tag={article.section ?? undefined}
              image={{ ...article.image, nativeLazyLoad: true }}
            />
          )
        })}
      </ds.LineGridLayout>
    </ActusSection>
  )
}

const ActusSection = styled(Section)`
  padding: 20px 0;

  ${Text} {
    @media screen and (${ds.breakpoint.LAPTOP}) {
      margin-top: 20px;
    }
  }
`

const Sticker = styled.div`
  margin: -62px 0 -62px calc(80% - 114px);
  position: relative;
  z-index: 1;

  @media not screen and (${ds.breakpoint.LAPTOP}) {
    display: none;
  }
`
