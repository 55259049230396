import * as React from 'react'
import { NextSeo } from 'next-seo'
import styled, { css, keyframes } from 'styled-components'
// import dynamic from 'next/dynamic'
// const Snowfall = dynamic(() => import('react-snowfall'), { ssr: false })

import * as entity from '@boutique/entities'
import { SITE_URL } from '@bob/config'
import * as ds from '@bob/design-system'
import * as url from '@bob/url'

// import { IS_XMAS } from './config'

import { Actus } from './Actus'
import { Brands } from './Brands'
import { Categories } from './Categories'
import { Guide } from './Guide'
import { HomeTestimonials } from './HomeTestimonials'
import { Manifesto } from './Manifesto'
import { Section } from './Section'
import { Partnership } from './Partnership'
import { Newsletter } from './Newsletter'

export type HomepageProps = {
  homepage: entity.homepage.Homepage
}

export function Homepage({ homepage }: HomepageProps): React.ReactElement {
  return (
    <>
      <NextSeo
        title="Bien ou Bien : le guide des marques engagées"
        description="Le guide qui met en lumière les marques engagées, respectueuses de la planète et des humains. Conseils pratiques, portraits et sélections, pour vous aider dans votre démarche de consommation éthique et durable."
        canonical={SITE_URL + url.homepage().path}
        openGraph={{
          title: 'Bien ou Bien : le guide des marques engagées',
          description:
            'Le guide qui met en lumière les marques engagées, respectueuses de la planète et des humains. Conseils pratiques, portraits et sélections, pour vous aider dans votre démarche de consommation éthique et durable.',
          url: SITE_URL,
          images: [
            {
              alt: 'Bien ou Bien : le guide des marques engagées',
              url: SITE_URL + '/assets/images/bob_og_image.png',
            },
          ],
        }}
        robotsProps={{
          maxImagePreview: 'large',
        }}
      />

      {/* {IS_XMAS && (
                <MobileXmasBackground>
                    <img
                        alt="fond de noël"
                        src="/assets/xmas/mobile-xmas-bg-1x.png"
                        srcSet="/assets/xmas/mobile-xmas-bg-1x.png 1x, /assets/xmas/mobile-xmas-bg-2x.png 2x, /assets/xmas/mobile-xmas-bg-3x.png 3x"
                        height={1665}
                        width={960}
                    />
                    <Snowfall
                        snowflakeCount={202}
                        speed={[0, 0.5]}
                        wind={[-0.5, 1]}
                        radius={[0.5, 2.5]}
                    />
                </MobileXmasBackground>
            )} */}

      <Wrapper>
        <ds.Container>
          <SectionWithCenteredTitle
            title={
              <>
                <MainTitle>
                  <Brush>Le guide des marques engagées</Brush>
                </MainTitle>
                <SecondaryTitle>respectueuses de la planète ET des humains !</SecondaryTitle>
              </>
            }
          />
        </ds.Container>

        <ds.Container>
          <Brands homepage={homepage} />
        </ds.Container>

        <ds.Container>
          <Partnership />
          <Manifesto />
          <Categories categoryList={homepage.categoryList} />
        </ds.Container>
        <ds.Container>
          <Newsletter />
        </ds.Container>

        <ds.Container>
          <Actus homepage={homepage} />
        </ds.Container>

        <ds.Container>
          <HomeTestimonials homepage={homepage} />
        </ds.Container>

        <Guide homepage={homepage} />
      </Wrapper>
    </>
  )
}

const NoWrap = css`
  white-space: initial;

  @media screen and (${ds.breakpoint.LAPTOP_L}) {
    white-space: nowrap;
  }
`

const MainTitle = styled(ds.styles.heading.Title2)`
  margin-bottom: 10px;
  ${NoWrap}
`
const SecondaryTitle = styled(ds.styles.heading.Title3)`
  ${NoWrap}
`

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: relative;
`

const SectionWithCenteredTitle = styled(Section)`
  padding-bottom: 40px;
  position: relative;

  ${ds.styles.heading.Title1} {
    text-align: center;
  }

  @media screen and (${ds.breakpoint.LAPTOP}) {
    padding-bottom: 80px;
  }
`

const slide = keyframes`
    from {
        left: -1em;
    }
    to {
        left: 130%;
    }
`

const BrushBackground = ds.utils.svg.toDataUrl(ds.brush.full.FullBrush2, {
  width: 482,
  height: 91,
  fill: ds.color.PINK,
})

const BaseBrush = styled.span`
  display: inline-block;
  position: relative;

  &::after {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    bottom: -0.5em;
    content: '';
    display: block;
    left: 0em;
    position: absolute;
    right: 0em;
    top: -0.4em;
    z-index: -2;
    transform: scaleX(1.2) scaleY(0.7);

    @media screen and (${ds.breakpoint.TABLET}) {
      transform: scaleX(2.4) scaleY(1);
    }

    @media screen and (${ds.breakpoint.LAPTOP}) {
      transform: scaleX(2.4) scaleY(0.7);
    }
  }
`
const SlideBrush = styled(BaseBrush)`
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: -0.5em;
    left: -1.25em;
    bottom: -0.5em;
    right: -1.25em;
  }
  &::before {
    z-index: -1;
    animation: ${slide} 0.4s ease-in-out forwards;
    animation-delay: 0.7s;
  }
`

const Brush = styled(SlideBrush)`
  &::before {
    background: ${ds.color.SAND_1};
  }

  &::after {
    background-image: url('${BrushBackground}');
  }
`
