import * as React from 'react'
import styled, { css } from 'styled-components'

import * as S from '../styles'

import { BlobBackground, BLOB_COLORS, BLOB_VARIANTS } from '../components/BlobBackground'

export type CategoryLinkProps = S.categoryLink.props &
  React.HTMLAttributes<HTMLDivElement> & {
    blob?: {
      color: keyof typeof BLOB_COLORS
      variant: keyof typeof BLOB_VARIANTS
    }
    illustration: React.ReactNode
    name: string
    vertical?: boolean
    active?: boolean
  }

function CategoryLinkComponent({
  blob,
  illustration,
  name,
  vertical = false,
  ...rest
}: CategoryLinkProps): React.ReactElement {
  return (
    <S.categoryLink.Link vertical={vertical} {...rest}>
      <S.categoryLink.Illustration vertical={vertical}>{illustration}</S.categoryLink.Illustration>
      <S.categoryLink.Title>{name}</S.categoryLink.Title>
      {blob && <BlobBackground variant={blob.variant} color={blob.color} />}
    </S.categoryLink.Link>
  )
}

export const CategoryLink = styled(CategoryLinkComponent)<{ active?: boolean }>`
  &:hover,
  &:focus {
    ${BlobBackground} {
      opacity: 1;
      transform: scale(1);
    }
  }

  ${BlobBackground} {
    position: absolute;

    opacity: 0;
    transition-timing-function: ease-in-out;
    transition-property: opacity, transform;
    transition-duration: 300ms;
    transform: scale(0.8);

    ${({ vertical }) =>
      vertical
        ? css`
            top: -10px;
            left: -17px;
            width: 100px;
            height: 86px;
          `
        : css`
            top: -5px;
            left: calc(50% - 60px);
            width: 120px;
            height: 120px;
          `}
  }
  ${({ active }) =>
    active &&
    css`
      ${BlobBackground} {
        opacity: 1;
        transform: scale(1);
      }
    `}
`
